import React, { useState } from "react";
import img from "../assets/Spinner-3.gif";
import imageCompression from "browser-image-compression";
import Swal from "sweetalert2";

export const New_BgcForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  /* aplicant_id  */
  const [aplicant_id, setApplicant_Id] = useState("");

  /* first form field */
  const [name, setName] = useState("");
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [gender, setGender] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [enumber, setEnumber] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [location, setLocation] = useState("");

  /*second form ...... */
  const [institute, setInstitute] = useState("");
  const [qualification, setQualification] = useState("");
  const [from_edu, setFrom_Edu] = useState("");
  const [grade, setGrade] = useState("");
  const [city, setCity] = useState("");
  const [graduation, setGraduation] = useState("");
  const [to_edu, setTo_Edu] = useState("");

  const [marksheet10, setMarksheet10] = useState(null);
  const [marksheet12, setMarksheet12] = useState(null);
  const [graduation_marksheet, setGraduation_Marksheet] = useState(null);
  const [other_document1, setOther_Document1] = useState(null);
  const [provisional_degree, setProvisional_Degree] = useState(null);

  /* Third form field */
  const [aadhar_front_side, setAadhar_Front_Side] = useState("");
  const [aadhar_back_side, setAadhar_Back_Side] = useState("");
  const [candidate, setCandidateId] = useState("");
  const [passport_size_photo, setPassport_Size_Photo] = useState("");

  const [currentStep, setCurrentStep] = useState(1);

  /*first page next......... */
  const nextStep1 = () => {
    if (
      name &&
      fname &&
      mname &&
      gender &&
      date &&
      email &&
      number &&
      enumber &&
      aadhar_front_side &&
      companyname &&
      passport_size_photo &&
      aadhar_back_side
    ) {
      const emailR =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailR.test(email)) {
        setCurrentStep((prevStep) => prevStep + 1);
      } else {
        alert("Please enter a valid email address");
      }
    } else {
      alert("Please fill out all required fields");
    }
  };

  /*Second page next......... */
  const nextStep2 = () => {
    if (
      institute &&
      qualification &&
      from_edu &&
      to_edu &&
      grade &&
      city &&
      marksheet10 &&
      marksheet12 &&
      graduation &&
      graduation_marksheet &&
      provisional_degree
    ) {
      setCurrentStep((prevStep) => prevStep);
    } else {
      alert("Please fill out all required fields");
    }
  };
  const nextStep3 = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };
  const nextStep4 = () => {
    setCurrentStep((prevStep) => prevStep + 2);
  };
  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const clearValue = () => {
    setName("");
    setFname("");
    setMname("");
    setGender("");
    setDate("");
    setEmail("");
    setNumber("");
    setEnumber("");
    setCompanyName("");
    setAadhar_Front_Side("");
    setInstitute("");
    setQualification("");
    setFrom_Edu("");
    setGrade("");
    setCity("");
    setGraduation("");
    setTo_Edu("");
    setMarksheet10("");
    setMarksheet12("");
    setGraduation_Marksheet("");
    setOther_Document1("");
    setProvisional_Degree("");
    setPassport_Size_Photo("");
  };

  const BgcFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("full_name", name);
    formData.append("fathers_name", fname);
    formData.append("mothers_name", mname);
    formData.append("gender", gender);
    formData.append("dob", date);
    formData.append("email", email);
    formData.append("contact_number", number);
    formData.append("emergency_number", enumber);
    formData.append("name_of_the_client", companyname);
    formData.append("client_location", location);
    formData.append("adhar_frontside", aadhar_front_side);
    formData.append("adhar_backside", aadhar_back_side);
    formData.append("photo", passport_size_photo);
    formData.append("institute_name", institute);
    formData.append("qualification", qualification);
    formData.append("from_edu", from_edu);
    formData.append("to_edu", to_edu);
    formData.append("grade_edu", grade);
    formData.append("city_edu", city);
    formData.append("marksheet_10", marksheet10);
    formData.append("marksheet_12", marksheet12);
    formData.append("higher_education", graduation);
    formData.append("higher_education_marksheet", graduation_marksheet);
    formData.append("higher_education_degree_provisional", provisional_degree);
    formData.forEach((key, value) => {
      console.log(key, value);
    });

    try {
      let response = await fetch(
        "https://humhai.in/background-verification/featured-form/",
        {
          method: "POST",
          body: formData,
        }
      );

      let data = await response.json();
      console.log(data.status, "data");
      setCandidateId(data.id);
      console.log(candidate, "candidate");

      if (data.status === 200) {
        console.log("data.status", data.status);
        // alert(`text: Copy/ScreenShot - Your HH ID: ${data.candidate}`);
        // alert(
        //   `Your form has been successfully submitted. A reference ID will be provided once your data has been checked.`
        // );
        nextStep3();
        clearValue();
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [selectExperience, setSelectExperience] = useState(false);
  const [fresher, setFresher] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFormValues = [...formValues];
    updatedFormValues[index][name] = value;
    setFormValues(updatedFormValues);
  };

  const handleFileChange1 = async (event, index, fieldName) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const fileType = file.type;

        if (
          fileType === "application/pdf" ||
          fileType === "image/jpeg" ||
          fileType === "image/png"
        ) {
          let fileToSet = file;
          if (fileType !== "application/pdf") {
            const options = {
              maxSizeMB: 0.2,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            fileToSet = await imageCompression(file, options);
          }
          const updatedFormValues = [...formValues];
          updatedFormValues[index][fieldName] = fileToSet;
          setFormValues(updatedFormValues);
        } else {
          console.error(
            "Unsupported file format. Please upload a PDF, JPG, JPEG, or PNG file."
          );
        }
      } catch (error) {
        console.error("Error processing file:", error);
      }
    }
  };

  const [formValues, setFormValues] = useState([
    {
      previous_company_name: "",
      previous_designation: "",

      hr_name: "",
      Hr_number: "",
      Hr_email_id: "",

      Employee_id: "",
      previous_salary: "",

      reason_of_leaving: "",
      previous_manager: "",

      previous_manager_number: "",
      previous_manager_email_id: "",

      from_previous_company: "",
      to_previous_company: "",

      location: "",
      work_reponsibility: "",

      payslip: "",
      pay_slip2: "",
      pay_slip3: "",

      offer_letter: "",
      resignation: "",
      experience_letter: "",
    },
  ]);

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        previous_company_name: "",
        previous_designation: "",

        hr_name: "",
        Hr_number: "",
        Hr_email_id: "",

        Employee_id: "",
        previous_salary: "",

        reason_of_leaving: "",
        previous_manager: "",

        previous_manager_number: "",
        previous_manager_email_id: "",

        from_previous_company: "",
        to_previous_company: "",

        location: "",
        work_reponsibility: "",

        payslip: "",
        pay_slip2: "",
        pay_slip3: "",

        offer_letter: "",
        resignation: "",
        experience_letter: "",
      },
    ]);
  };

  const handleFileChange = async (event, setStateFunction) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const fileType = file.type;

        if (
          fileType === "application/pdf" ||
          fileType === "image/jpeg" ||
          fileType === "image/png"
        ) {
          if (fileType === "application/pdf") {
            setStateFunction(file);
          } else {
            const options = {
              maxSizeMB: 0.2,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };

            const compressedFile = await imageCompression(file, options);
            setStateFunction(compressedFile);
          }
        } else {
          console.error(
            "Unsupported file format. Please upload a PDF, JPG, JPEG, or PNG file."
          );
        }
      } catch (error) {
        console.error("Error processing file:", error);
      }
    }
  };

  const EmploymentSubmit = async (e) => {
    setIsLoading(true);
    console.log("hii");
    e.preventDefault();
    const isAnyFieldEmpty = formValues.some((form) =>
      Object.entries(form).some(([key, value]) => {
        if (typeof value === "string" && value.trim() === "") {
          return true;
        }

        if (key.includes("email") && value.trim() !== "") {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(value)) {
            alert("Please enter a valid email address");
            return true;
          }
        }

        return false;
      })
    );

    if (isAnyFieldEmpty) {
      alert("Please fill all required fields");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formValues.forEach((form, index) => {
      formData.append(`previous_company_name`, form.previous_company_name);
      formData.append(`previous_designation`, form.previous_designation);

      formData.append(`hr_name`, form.hr_name);
      formData.append(`Hr_number`, form.Hr_number);
      formData.append(`Hr_email_id`, form.Hr_email_id);

      formData.append(`Employee_id`, form.Employee_id);
      formData.append(`previous_salary`, form.previous_salary);

      formData.append(`reason_of_leaving`, form.reason_of_leaving);
      formData.append(`previous_manager`, form.previous_manager);

      formData.append(`previous_manager_number`, form.previous_manager_number);
      formData.append(
        `previous_manager_email_id`,
        form.previous_manager_email_id
      );

      formData.append(`from_previous_company`, form.from_previous_company);
      formData.append(`to_previous_company`, form.to_previous_company);

      formData.append(`location`, form.location);
      formData.append(`work_reponsibility`, form.work_reponsibility);
      // console.log(form.length,"hii")

      formData.append(`payslip`, form.payslip);
      formData.append(`pay_slip2`, form.pay_slip2);
      formData.append(`pay_slip3`, form.pay_slip3);

      formData.append(`offer_letter`, form.offer_letter);
      formData.append(`resignation`, form.resignation);
      formData.append(`experience_letter`, form.experience_letter);
    });
    formData.append("candidate", candidate);
    formData.append("fresher", fresher);

    let response = await fetch(
      "https://humhai.in/background-verification/employment",
      {
        method: "POST",
        // headers: {
        //   // "Content-Type": "multipart/form-data",
        //   'Content-Type':' image/jpeg',
        // },
        body: formData,
      }
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
    // console.log("response");
    let data = await response.json();
    // console.log(data, "data");
    // console.log(data.status, "status");
    if (data.status === 200) {
      // console.log(data.status, "data");
      addFormFields();
      setIsLoading(false);
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
      });
      setIsLoading(false);
    }
  };

  const EmploymentSubmit1 = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const isAnyFieldEmpty = formValues.some((form) =>
      Object.entries(form).some(([key, value]) => {
        if (typeof value === "string" && value.trim() === "") {
          return true;
        }

        if (key.includes("email") && value.trim() !== "") {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(value)) {
            alert("Please enter a valid email address");
            return true;
          }
        }

        return false;
      })
    );

    if (isAnyFieldEmpty) {
      alert("Please fill all required fields");
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formValues.forEach((form, index) => {
      formData.append(`previous_company_name`, form.previous_company_name);
      formData.append(`previous_designation`, form.previous_designation);

      formData.append(`hr_name`, form.hr_name);
      formData.append(`Hr_number`, form.Hr_number);
      formData.append(`Hr_email_id`, form.Hr_email_id);

      formData.append(`Employee_id`, form.Employee_id);
      formData.append(`previous_salary`, form.previous_salary);

      formData.append(`reason_of_leaving`, form.reason_of_leaving);
      formData.append(`previous_manager`, form.previous_manager);

      formData.append(`previous_manager_number`, form.previous_manager_number);
      formData.append(
        `previous_manager_email_id`,
        form.previous_manager_email_id
      );

      formData.append(`from_previous_company`, form.from_previous_company);
      formData.append(`to_previous_company`, form.to_previous_company);

      formData.append(`location`, form.location);
      formData.append(`work_reponsibility`, form.work_reponsibility);

      formData.append(`payslip`, form.payslip);
      formData.append(`pay_slip2`, form.pay_slip2);
      formData.append(`pay_slip3`, form.pay_slip3);

      formData.append(`offer_letter`, form.offer_letter);
      formData.append(`resignation`, form.resignation);
      formData.append(`experience_letter`, form.experience_letter);
    });

    formData.append("candidate", candidate);
    formData.append("fresher", fresher);

    try {
      let response = await fetch(
        "https://humhai.in/background-verification/employment",
        {
          method: "POST",
          body: formData,
        }
      );
      let data = await response.json();
      // console.log(data,"data")

      if (data.status === 200) {
        // alert(
        //   `name : ${data.name} mobile no.: ${data.mobile} reference Id : ${data.reference_id}  submitted as Experience`
        // );
        alert(
          `Your form has been successfully submitted. A reference ID will be provided once your data has been checked.`
        );
        setIsLoading(false);
        clearValue();
        window.location.reload();
      } else {
        throw new Error(data.message || "Failed to submit data");
      }
    } catch (error) {
      // console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: error.message || "Failed to submit data",
      });
      setIsLoading(false);
    }
  };

  const checkAplicantId = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("reference_id", aplicant_id);

    try {
      let response = await fetch(
        "https://humhai.in/background-verification/featured-form/",
        {
          method: "POST",
          body: formData,
        }
      );
      let data = await response.json();
      setCandidateId(data.id);

      if (response.ok) {
        setIsLoading(false);
        nextStep4();
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      // console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: error.message,
      });
      setSuccess(false);
      setIsLoading(false);
    }
  };

  const EmploymentSubmit2 = async (fresherValue) => {
    const formData = new FormData();
    formData.append("fresher", fresherValue);
    formData.append("candidate", candidate);

    try {
      let response = await fetch(
        "https://humhai.in/background-verification/employment",
        {
          method: "POST",
          body: formData,
        }
      );
      let data = await response.json();
      // console.log(data.status,"data")

      if (data.status === 200) {
        // alert(
        //   `name : ${data.name || "Unknown"} mobile no.: ${
        //     data.mobile || "Unknown"
        //   } reference Id : ${
        //     data.reference_id || "Unknown"
        //   } submitted as fresher`
        // );
        alert(
          `Your form has been successfully submitted. A reference ID will be provided once your data has been checked.`
        );

        Swal.fire({
          icon: "success",
          title: response.message,
        });
        setSuccess(false);
        clearValue();
        window.location.reload();
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      // console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: error.message,
      });
      setSuccess(false);
    }
  };

  const hideEmployment = () => {
    const selected = document.getElementById("employment1").value;
    if (selected === "Experience") {
      setSelectExperience("Experience");
      setFresher(false);
    } else if (selected === "Fresher") {
      const confirmed = window.confirm(
        "Are You Sure You Want To Submit This Form As Fresher ?"
      );
      if (confirmed) {
        setFresher(true);
        EmploymentSubmit2(true);
      } else {
        console.log("Cancel clicked");
      }
    } else {
      setSelectExperience("");
    }
  };

  return (
    <div>
      <div id="regForm" encType="multipart/form-data">
        <input
          type="hidden"
          name="csrfmiddlewaretoken"
          value="rXPcP9QunvYuPOuSr55cXHpozG0hvOovqlifTwz9TER1IFe6RbR7dIFp1PosY8N8"
        />
        <h1>Employement Verification</h1>
        {/* First Form ...... */}
        {currentStep === 1 && (
          <div>
            <div className="personal">
              <div
                className="h3 text-center mb-5"
                style={{
                  marginTop: "60px",
                  fontWeight: "bold",
                  fontSize: "34px",
                }}
              >
                CANDIDATE
                <span style={{ color: "rgb(255, 119, 0)" }}>
                  {" "}
                  PERSONAL
                </span>{" "}
                DETAILS
              </div>
              <h3
                className=" text-center"
                style={{ color: "rgb(255, 119, 0)" }}
              >
                * Upload a Fully Clear and Completed Document *
              </h3>
              <div className="bg-light p-3 rounded-3 p-3 mx-auto border border-opacity-50">
                <p style={{ margin: "10px" }}>
                  If you have already submitted your HH ID, please enter your ID
                  to proceed with the further process.{" "}
                </p>
                <div className="row ms-4 mb-4">
                  <label htmlFor="name_of_applicant" className="form-label">
                    Applicant HH Id*:
                  </label>
                  <br />
                  <div className="col-md-6 d-flex align-items-end">
                    <input
                      className="inputtype bg-light mb-5 form-control"
                      list="name_of_applicant_id"
                      value={aplicant_id}
                      onChange={(e) => setApplicant_Id(e.target.value)}
                      name="name_of_applicant_id"
                      maxLength="100"
                      required
                    />
                  </div>
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "1",
                      }}
                    >
                      <img src={img} alt="" />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-md-6 ">
                    <button
                      type="button"
                      className="tablinks mb-2 ms-2"
                      onClick={checkAplicantId}
                    >
                      Next
                    </button>
                  </div>
                </div>
                <hr style={{ margin: "0px 10px 30px" }} />

                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="name_of_applicant" className="form-label">
                      Name of the Applicant:
                    </label>
                    <br />
                    <input
                      className="inputtype bg-light mb-5 form-control"
                      list="recruiters"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      name="name_of_applicant"
                      maxLength="100"
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="Input2" className="form-label">
                      Fathers/Husband/Wife Name
                    </label>
                    <br />
                    <input
                      className="inputtype bg-light mb-5 form-control"
                      list="projects"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                      name="fathers_name"
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input3" className="form-label">
                      Mother's Name
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={mname}
                      onChange={(e) => setMname(e.target.value)}
                      name="mothers_name"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input4" className="form-label">
                      Applicant Gender
                    </label>{" "}
                    <br />
                    <select
                      className="inputtype bg-light mb-5 form-control"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      name="gender"
                    >
                      <option value="">choose</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="other">other</option>
                    </select>
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6 input-icons">
                    <label htmlFor="Input5" className="form-label">
                      Date Of Birth
                    </label>
                    <br />
                    <div className="date-container">
                      <input
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        type="date"
                        className="inputtype bg-light mb-5 form-control"
                        name="dob"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input6" className="form-label">
                      Email Id
                    </label>{" "}
                    <br />
                    <input
                      type="email"
                      className="inputtype bg-light mb-5 form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6 input-icons">
                    <label htmlFor="Input5" className="form-label">
                      Contact Number
                    </label>
                    <br />
                    <div className="date-container">
                      <input
                        type="number"
                        className="inputtype bg-light mb-5 form-control"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        name="contact_number"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input6" className="form-label">
                      Emergency Contact
                    </label>
                    <br />
                    <input
                      type="number"
                      className="inputtype bg-light mb-5 form-control"
                      value={enumber}
                      onChange={(e) => setEnumber(e.target.value)}
                      name="emergency_contact"
                    />
                  </div>
                </div>
                <div className="row ms-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Company Name
                    </label>
                    <br />
                    <input
                      type="text"
                      className="inputtype bg-light mb-5 form-control"
                      value={companyname}
                      onChange={(e) => setCompanyName(e.target.value)}
                      name="name_of_client"
                      maxLength="100"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input7" className="form-label">
                      Company Location
                    </label>
                    <br />
                    <input
                      type="text"
                      className="bg-light mb-5 form-control"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      name="client_location"
                      maxLength="30"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input8" className="form-label">
                      Aadhar Card FRONT SIDE
                    </label>
                    <br />
                    <span style={{ color: 'red', fontSize: 'small' }}> (use .jpg and .png format)</span>
                    <input
                      type="file"
                      className="inputtype bg-light mb-5 form-control file-upload"
                      onChange={(e) =>
                        handleFileChange(e, setAadhar_Front_Side)
                      }
                      name="aadhar_front"
                       accept=".png, .jpg"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input8" className="form-label">
                      Aadhar Card Back SIDE
                    </label>
                    <br />
                    <span style={{ color: 'red', fontSize: 'small' }}> (use .jpg and .png format)</span>
                    <input
                      type="file"
                      className="inputtype bg-light mb-5 form-control file-upload"
                      onChange={(e) => handleFileChange(e, setAadhar_Back_Side)}
                      name="aadhar_back_side"
                       accept=".png, .jpg"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Input9" className="form-label">
                      Passport Size Photo
                    </label>
                    <br />
                    <span style={{ color: 'red', fontSize: 'small' }}> (use .jpg and .png format)</span>
                    <input
                      type="file"
                      className="inputtype bg-light mb-5 form-control file-upload"
                      // value={passport_size_photo}
                      onChange={(e) =>
                        handleFileChange(e, setPassport_Size_Photo)
                      }
                      name="passport_photo"
                       accept=".png, .jpg"
                    />
                  </div>
                </div>
              </div>
              <div style={{ overflow: "auto" }}>
                <div style={{ float: "right" }}>
                  <button
                    type="button"
                    className="tablinks mb-2 ms-2"
                    onClick={nextStep1}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Second Form ...... */}
        {currentStep === 2 && (
          <div>
            <div className="education bg-light rounded-3 p-2 mx-auto mb-3 border border-opacity-25">
              <div
                className="h3 text-center mb-5"
                style={{
                  marginTop: "60px",
                  fontWeight: "bold",
                  fontSize: "34px",
                }}
              >
                CANDIDATE
                <span style={{ color: "rgb(255, 119, 0)" }}>
                  {" "}
                  EDUCATION
                </span>{" "}
                DETAILS
              </div>

              <h4 className=" text-center mb-2">
                Please Remove All Password From The Documents
              </h4>
              <h5 className=" text-center mb-5">
                <a href="https://www.ilovepdf.com/unlock_pdf">
                  Click here to Remove Password
                </a>
              </h5>
              <h3
                className=" text-center"
                style={{ color: "rgb(255, 119, 0)" }}
              >
                * Upload a Fully Clear and Completed Document *
              </h3>
              <hr />
              <div className="row p-4">
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="organisation">Institute Name</label>
                  <br />
                  <input
                    type="text"
                    value={institute}
                    onChange={(e) => setInstitute(e.target.value)}
                    name="institute_name"
                    className=" bg-light mt-3 form-control"
                    maxLength="100"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="designation">Qualification</label>
                  <br />
                  <input
                    type="text"
                    value={qualification}
                    onChange={(e) => setQualification(e.target.value)}
                    name="qualification"
                    className=" bg-light mt-3 file-upload form-control"
                    maxLength="100"
                  />
                </div>
              </div>
              <div className="row p-4">
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="organisation">From</label>
                  <br />
                  <input
                    type="date"
                    value={from_edu}
                    onChange={(e) => setFrom_Edu(e.target.value)}
                    name="from_edu"
                    className=" bg-light mt-3 form-control"
                  //   value="None"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="designation">To</label>
                  <br />
                  <input
                    type="date"
                    value={to_edu}
                    onChange={(e) => setTo_Edu(e.target.value)}
                    name="to_edu"
                    className=" bg-light mt-3 file-upload form-control"
                  //   value="None"
                  />
                </div>
              </div>
              <div className="row p-4">
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="organisation">Grades</label>
                  <br />
                  <input
                    type="text"
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                    name="grade_edu"
                    className=" bg-light mt-3 form-control"
                    maxLength="10"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="City">City</label>
                  <br />
                  <input
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    name="city_edu"
                    className=" bg-light mt-3 file-upload form-control"
                    maxLength="20"
                  />
                </div>
              </div>
              <div className="row p-4">
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="10th Marksheet">10th Marksheet</label>
                  <span style={{ color: 'red', fontSize: 'small' }}> (use .jpg and .png format)</span>
                  <br />
                  <input
                    type="file"
                    // value={marksheet10}
                    onChange={(e) => handleFileChange(e, setMarksheet10)}
                    name="marksheet_10"
                    className=" bg-light mt-3 form-control"
                    accept=".png, .jpg"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="12th Marksheet">12th Marksheet</label>
                  <span style={{ color: 'red', fontSize: 'small' }}> (use .jpg and .png format)</span>
                  <br />
                  <input
                    type="file"
                    // value={marksheet12}
                    onChange={(e) => handleFileChange(e, setMarksheet12)}
                    name="marksheet_12"
                    className=" bg-light mt-3 file-upload form-control"
                    accept=".png, .jpg"
                  />
                </div>
              </div>
              <div className="row p-4">
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="10th Marksheet">
                    Graduation/Post Graduation
                  </label>
                  <br />
                  <select
                    className="form-control mt-3"
                    name="higher_education"
                    value={graduation}
                    onChange={(e) => setGraduation(e.target.value)}
                  >
                    <option value="">Choose</option>
                    <option value="BA">BA</option>
                    <option value="B.COM">B.COM</option>
                    <option value="BSc">BSc</option>
                    <option value="MA">MA</option>
                    <option value="MBA">MBA</option>
                    <option value="B.TECH">B.TECH</option>
                    <option value="M.TECH">M.TECH</option>
                    <option value="M.COM">M.COM</option>
                    <option value="M.Sc">M.Sc</option>
                    <option value="other" id="otherprogram">
                      Other program
                    </option>
                  </select>
                </div>
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="12th Marksheet">
                    Graduation/Post Graduation Marksheet
                  </label>
                  <span style={{ color: 'red', fontSize: 'small' }}> (use .jpg and .png format)</span>
                  <br />
                  <input
                    type="file"
                    // value={graduation_marksheet}
                    onChange={(e) =>
                      handleFileChange(e, setGraduation_Marksheet)
                    }
                    name="higher_education_marksheet"
                    className=" bg-light mt-3 file-upload form-control"
                    accept=".png, .jpg"
                  />
                </div>
              </div>
              <div className="row p-4">
                <div
                  id="othertext"
                  className="col-md-6 col-sm-12 mb-5"
                  style={{ display: "none" }}
                >
                  <label htmlFor="City">Type Other Program Name</label>
                  <br />
                  <input
                    type="text"
                    // value={other_document1}
                    onChange={(e) => handleFileChange(e, setOther_Document1)}
                    name="other_document1"
                    className=" bg-light mt-3 file-upload form-control"
                    maxLength="20"
                  />
                </div>
                <div className="col-md-6 col-sm-12 mb-5">
                  <label htmlFor="12th Marksheet">
                    Graduation/Post Graduation Degree/Provisional Degree
                  </label>
                  <br />
                  <span style={{ color: 'red', fontSize: 'small' }}> (use .jpg and .png format)</span>
                  <input
                    type="file"
                    // value={provisional_degree}
                    onChange={(e) => handleFileChange(e, setProvisional_Degree)}
                    name="higher_education_degree_provisional"
                    className=" bg-light mt-3 file-upload form-control"
                    accept=".png, .jpg"
                  />
                </div>
                <div className="row ms-4 mb-4">
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "1",
                        flexDirection: "column",
                      }}
                    >
                      {" "}
                      <img src={img} alt="" />
                      <div style={{ color: "#DE3500", marginTop: "10px" }}>
                        Processing Your Request, Please Hold On...
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div style={{ overflow: "auto" }}>
              <div style={{ float: "right" }}>
                <button
                  type="button"
                  style={{ backgroundColor: "#BBBBBB" }}
                  className="tablinks mb-2 ms-2"
                  onClick={prevStep}
                  disabled={isLoading}
                >
                  Previous
                </button>
                <button
                  type="button"
                  className="tablinks mb-2 ms-2"
                  onClick={(e) => {
                    BgcFormSubmit(e);
                    nextStep2();
                  }}
                  disabled={isLoading}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}

        {currentStep === 3 && (
          <div style={{ height: "auto" }}>
            <div className="employement bg-light rounded-3 p-2 mx-auto mb-3 border border-opacity-25">
              <div
                className="h3 text-center mb-5"
                style={{
                  marginTop: "60px",
                  fontWeight: "bold",
                  fontSize: "34px",
                }}
              >
                CANDIDATE
                <span style={{ color: "rgb(255, 119, 0)" }}>
                  {" "}
                  PREVIOUS EMPLOYEMENT
                </span>{" "}
                DETAILS
              </div>
              <h4 className=" text-center mb-2">
                Please Remove All Password From The Documents
              </h4>
              <h5 className=" text-center mb-5">
                <a href="https://www.ilovepdf.com/unlock_pdf">
                  Click here to Remove Password
                </a>
              </h5>
              <div className="col-md-6 col-sm-12 mb-5">
                <label>Select Your Employment Status : </label>
                <br />
                <select
                  className="form-control mt-3"
                  name="choose_tenancy"
                  id="employment1"
                  onChange={hideEmployment}
                >
                  <option value="">Choose</option>
                  <option value="Fresher">Fresher</option>
                  <option value="Experience">Experience</option>
                </select>
              </div>
              {selectExperience ? (
                <div>
                  {Array.isArray(formValues) &&
                    formValues.map((element, index) => (
                      <div
                        className="form-inline"
                        key={index}
                        disabled={index !== formValues.length - 1}
                      >
                        <div>
                          <div className="row p-4">
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="organisation">Company Name</label>
                              <br />
                              <input
                                type="text"
                                value={element.previous_company_name || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="previous_company_name"
                                className=" bg-light mt-3 form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="designation">Designation</label>
                              <br />
                              <input
                                type="text"
                                value={element.previous_designation || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="previous_designation"
                                className=" bg-light mt-3 file-upload form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="hr_name">HR name</label>
                              <br />
                              <input
                                type="text"
                                value={element.hr_name || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="hr_name"
                                className=" bg-light mt-3 form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="Hr_number">
                                HR Contact Number
                              </label>
                              <br />

                              <input
                                type="number"
                                value={element.Hr_number || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="Hr_number"
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="Hr_email_id">HR Email Id</label>
                              <br />
                              <input
                                type="email"
                                value={element.Hr_email_id || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="Hr_email_id"
                                className=" bg-light mt-3 form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="Employee_id">Employee ID</label>
                              <br />
                              <input
                                type="text"
                                value={element.Employee_id || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="Employee_id"
                                className=" bg-light mt-3 form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="previous_salary">
                                Annual CTC *
                              </label>
                              <br />
                              <input
                                type="number"
                                value={element.previous_salary || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="previous_salary"
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="reason_of_leaving">
                                Reason of leaving
                              </label>
                              <br />
                              <input
                                type="text"
                                value={element.reason_of_leaving || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="reason_of_leaving"
                                className=" bg-light mt-3 form-control"
                                maxLength="255"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="previous_manager">
                                Supervisor/Manager Name
                              </label>
                              <br />

                              <input
                                type="text"
                                value={element.previous_manager || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="previous_manager"
                                className=" bg-light mt-3 file-upload form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="previous_manager_number">
                                Supervisor/Manager contact number *
                              </label>
                              <br />
                              <input
                                type="number"
                                value={element.previous_manager_number || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="previous_manager_number"
                                className=" bg-light mt-3 form-control"
                                maxLength="255"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="previous_manager_email_id">
                                Supervisor/Manager Email id *
                              </label>
                              <br />
                              <input
                                type="email"
                                value={element.previous_manager_email_id || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="previous_manager_email_id"
                                className=" bg-light mt-3 file-upload form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="from_previous_company">
                                From
                              </label>
                              <br />
                              <input
                                type="date"
                                value={element.from_previous_company || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="from_previous_company"
                                className=" bg-light mt-3 form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="to_previous_company">To</label>
                              <br />
                              <input
                                type="date"
                                value={element.to_previous_company || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="to_previous_company"
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="organisation">Location</label>
                              <br />
                              <input
                                type="text"
                                value={element.location || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="location"
                                className=" bg-light mt-3 form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12 mb-5">
                              <label htmlFor="designation">
                                Work Responsibility
                              </label>
                              <br />
                              <input
                                type="text"
                                value={element.work_reponsibility || ""}
                                onChange={(e) => handleChange(index, e)}
                                name="work_reponsibility"
                                className=" bg-light mt-3 file-upload form-control"
                                maxLength="100"
                                disabled={index !== formValues.length - 1}
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="payslip">Pay Slip 1</label>
                              <span style={{ color: 'red', fontSize: 'small' }}> (use .jpg and .png format)</span>
                              <br />
                              <input
                                type="file"
                                name="payslip"
                                onChange={(e) =>
                                  handleFileChange1(e, index, "payslip")
                                }
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                                accept=".png, .jpg"
                              />
                            </div>
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="pay_slip2">Pay Slip 2</label>
                              <span style={{ color: 'red', fontSize: 'small' }}> (use .jpg and .png format)</span>
                              <br />
                              <input
                                type="file"
                                name="pay_slip2"
                                onChange={(e) =>
                                  handleFileChange1(e, index, "pay_slip2")
                                }
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                                accept=".png, .jpg"
                              />
                            </div>

                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="pay_slip3">Pay Slip 3</label>
                              <span style={{ color: 'red', fontSize: 'small' }}> (use .jpg and .png format)</span>
                              <br />
                              <input
                                type="file"
                                name="pay_slip3"
                                onChange={(e) =>
                                  handleFileChange1(e, index, "pay_slip3")
                                }
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                                accept=".png, .jpg"
                              />
                            </div>
                          </div>
                          <div className="row p-4">
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="offer_latter">Offer Letter</label>
                              <span style={{ color: 'red', fontSize: 'small' }}> (use .jpg and .png format)</span>
                              <br />
                              <input
                                type="file"
                                name="offer_letter"
                                onChange={(e) =>
                                  handleFileChange1(e, index, "offer_letter")
                                }
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                                accept=".png, .jpg"
                              />
                            </div>
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="resignation_latter">
                                Resignation Letter
                              </label>
                              <span style={{ color: 'red', fontSize: 'small' }}> (use .jpg and .png format)</span>
                              <br />
                              <input
                                type="file"
                                name="resignation"
                                onChange={(e) =>
                                  handleFileChange1(e, index, "resignation")
                                }
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                                accept=".png, .jpg"
                              />
                            </div>
                            <div className="col-md-4 col-sm-12 mb-5">
                              <label htmlFor="resignation_latter">
                                Experience Letter
                              </label>
                              <span style={{ color: 'red', fontSize: 'small' }}> (use .jpg and .png format)</span>
                              <br />
                              <input
                                type="file"
                                name="experience_letter"
                                onChange={(e) =>
                                  handleFileChange1(
                                    e,
                                    index,
                                    "experience_letter"
                                  )
                                }
                                className=" bg-light mt-3 file-upload form-control"
                                disabled={index !== formValues.length - 1}
                                accept=".png, .jpg"
                              />
                            </div>
                          </div>
                          <div className="row ms-4 mb-4">
                            {isLoading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  zIndex: "1",
                                  flexDirection: "column",
                                }}
                              >
                                {" "}
                                <img src={img} alt="" />
                                <div
                                  style={{
                                    color: "#DE3500",
                                    marginTop: "10px",
                                  }}
                                >
                                  Please Wait ......
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    ))}

                  <div className="button-section">
                    <button
                      className="tablinks mb-2 ms-2"
                      style={{ backgroundColor: "#FF7700" }}
                      type="button"
                      onClick={EmploymentSubmit}
                      disabled={isLoading}
                    >
                      Add
                    </button>
                    <div style={{ overflow: "auto" }}>
                      <div style={{ float: "right" }}>
                        <button
                          type="submit"
                          className="tablinks mb-2 ms-2"
                          onClick={EmploymentSubmit1}
                          disabled={isLoading}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
